import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";


const RedirectToSchool = () => {


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const referer = searchParams.get('referer');


useEffect(() => {
    window.location.href="https://e-campus-registration.bp.edu/special/breathing-science-for-birth-professionals?referer="+referer
})

return (
        <></>
    )
}

export default RedirectToSchool;
