import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import capnoKit_new from "../Assets/Images/new_1_.png";
// import capnoKit_svg from "../Assets/Images/capnoKit_svg.svg";
import capno_trainer_img from "../Assets/Images/book.png";
// import cart_bg from "../Assets/Images/cart_bg.png";
import CustomTypography from "../Components/CustomTypography";
import Title from "../Components/Title";
import Copyright from "../Components/Copyright";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../Components/Navbar";
import { useTranslation } from "react-i18next";
// import ThreeSixty from "react-360-view";
import SyncIcon from "@mui/icons-material/Sync";
import { Link } from "react-router-dom";
import ViewImages from "../Components/ViewImages";
import VideoPop from "./GoVideos/VideoPop";

export const images = [];

// Generating image paths up to 72
for (let i = 1; i <= 72; i++) {
  images.push(`/untitled/updated_img/${i}.png`);
}

const Home = () => {
  const { t } = useTranslation();
  const [view,setView]= useState(false)
  useEffect(() => {
    AOS.init();
  }, []);

  const [rotationDisabled, setRotationDisabled] = useState(false);
  const [videoModal, setVideoModal] = useState(false);

  const handleKeyDown = (ev) => {
    if (rotationDisabled) return;
    if (ev.key === "ArrowLeft" || ev.key === "ArrowRight") {
      setRotationDisabled(true);
    }
  };
  return (
    <Container>
      <Navbar />
      <Box sx={{ color: "#000", mt: "1rem","& p":{
        // textAlign:"justify !important"
      }}} data-aos="fade-up">
        <Title title={t(`The CapnoTrainer GO`)} />
        {/* <Typography
            sx={{
              fontSize: { sm: "1.5rem", xs: "1.2rem" },
              fontFamily: "ABLE",
              pb:"1rem",
              color:"#007aff",
              cursor:"pointer",
              display:"inline-block"
            }}
            onClick={()=>setVideoModal(true)}
          >
           Watch Dr. Marcola share his thoughts on the Capnotrainer Go.
          </Typography> */}
        <Grid
          sx={{
            display: { md: "none", sm: "block" },
            textAlign: "center",
            position: "relative",
            "&:hover": {
              "& .MuiTypography-root": {
                // display: "none",
                background: "#0000007d",
                opacity: "0.8rem",
                padding: "0.6rem",
                borderRadius: "0.5rem",
                // display: "none",
                display: "none ! important",
              },
            },
          }}
        >
          <Typography
            sx={{
              // fontSize: { sm: "2.25rem", xs: "1.5rem" },
              fontSize: { sm: "1.375rem", xs: "1.2rem" },
              fontFamily: "ABLE",
              lineHeight: { sm: "2.1rem", xs: "1.8rem" },
              // lineHeight: { sm: "2.868rem", xs: "2rem" },
              textAlign: { sm: "start", xs: "justify" },
              pb: "1rem",
              pt: { xs: "0.5rem" },
            }}
          >
            The CapnoTrainer® GO brings together physiology, psychology, and
            technology to create powerful learning tools for identifying good
            and bad breathing habits, for learning to disengage bad ones that
            compromise physical and mental competence, and for learning new ones
            that promote health and performance.
          </Typography>
          {/* <Typography
            component={"p"}
            sx={{
              color: "#fff",
              position: "absolute",
              left: "20%",
              top: "70%",
              zIndex: 10,
              background: "#0000007d",
              opacity: "0.8rem",
              padding: "0.6rem",
              borderRadius: "0.5rem",
              "@media (max-width:400px)": {
                fontSize: "0.7rem",
              },
              display: "flex",
              alignItems: "center",
              gap: "2px",

              "&:hover": {
                background: "#0000007d",
                opacity: "0.8rem",
                padding: "0.6rem",
                borderRadius: "0.5rem",
                // display: "block !important",
                display: "none ! important",
              },
            }}
          >
            Hold mouse to drag and rotate{" "}
            <SyncIcon
              sx={{
                color: "white",
                fontSize: "1rem",
                "@media (max-width:400px)": {
                  fontSize: "0.7rem",
                },
              }}
            />
          </Typography>
        
          <ReactImageTurntable
            initialImageIndex={24}
            images={images}
            autoRotate={{
              disabled: rotationDisabled,
              interval: 100000000,
              rotationDisabled: true,
            }}
            onPointerDown={() => setRotationDisabled(true)}
            onPointerUp={() => setRotationDisabled(false)}
            onKeyDown={handleKeyDown}
            onKeyUp={() => setRotationDisabled(false)}
            style={{
              cursor: "grab",
            }}
          />
          <Typography
            sx={{
              // width:{sm:"55%",xs:"100%"},
              margin: "0 auto",
              fontSize: { sm: "18px", xs: "18px" },
              fontFamily: "ABLE",
              lineHeight: { sm: "32px", xs: "28px" },
              textAlign: { sm: "end", xs: "center" },
              color: "#3c0742",
              fontWeight: "600",
            }}
          >
            NOTE:{" "}
            <Typography
              component={"span"}
              sx={{
                fontSize: { sm: "18px", xs: "18px" },
                fontFamily: "ABLE",
                textAlign: { sm: "start", xs: "center" },
                color: "#040404",
                fontWeight: "600",
              }}
            >
              Image larger than instrument <br />
              <Typography
                component={"span"}
                sx={{ fontWeight: "300", fontFamily: "ABLE" }}
              >
                (Actual size: 9.7 X 7.6 X 3.2 cm)
              </Typography>
            </Typography>
          </Typography> */}
        </Grid>
        <Grid container spacing={0} pt="0.6rem">
          <Grid item md={7.4} sm={12} xs={12}>
            <CustomTypography
              text={`The instrument is a combination hardware and software system that
              provides users with training and learning tools for personalized
              breathing education based on the principles of behavioral analysis
              through the presentation of both live and recorded physiology.
              There are three versions of the CapnoTrainer® GO: Personal, Basic,
              and Professional.`}
            />
            <CustomTypography
              text={`The GO is a uniquely powerful learning tool for a wide range of
              educated audiences with a serious interest in breathing science
              including both practitioners and their clients: human service
              providers, health educators, fitness trainers, mental health
              professionals, corporate coaches, counselors, teachers, personal
              trainers, performance consultants, and self-help specialists
              (e.g., yoga and meditation).`}
            />
            <CustomTypography
              text={`The GO is battery-operated, Bluetooth, wearable, light weight (185
              grams), small (fits in your pocket), and nearly silent (slight
              ticking sound). It operates with PC and Apple computers, tablets
              and I-pads, and Android and i-phones. It connects to most
              third-party HRV Bluetooth devices and will operate up to six GO
              and HRV devices simultaneously.`}
            />
            <Typography
              sx={{
                fontSize: { sm: "1.375rem", xs: "1.2rem" },
                fontFamily: "ABLE",
                lineHeight: "22px",
                py: "0.5rem",
                // textAlign:{sm:"inherit",xs:"center"}
              }}
            >
              Click here for a{" "}
              <Typography
                component={"a"}
                href="/go-brochure"
                target="_blank"
                color={"rgb(109 79 160)"}
                fontWeight={600}
                sx={{
                  fontSize: { sm: "1.375rem", xs: "1.2rem" },
                  fontFamily: "ABLE",
                }}
              >
                BROCHURE
              </Typography>{" "}
              about the GO. Click here to make a{" "}
              <Typography
                component={"a"}
                href="/shopping-cart"
                target="_blank"
                color={"rgb(109 79 160)"}
                fontWeight={600}
                sx={{
                  fontSize: { sm: "1.375rem", xs: "1.2rem" },
                  fontFamily: "ABLE",
                }}
              >
                PURCHASE
              </Typography>
              .
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "1.375rem", xs: "1.2rem" },
                fontFamily: "ABLE",
                lineHeight: "22px",
                pb: "0.5rem",
              }}
            >
              Click here to find out about the new CapnoLearning{" "}
              <Typography
                component={"a"}
                href="/book"
                target="_blank"
                color={"rgb(109 79 160)"}
                fontWeight={600}
                sx={{
                  fontSize: { sm: "1.375rem", xs: "1.2rem" },
                  fontFamily: "ABLE",
                }}
              >
                e-book.
              </Typography>{" "}
             
            </Typography>

            <Typography
              sx={{
                fontSize: { sm: "1.375rem", xs: "1.2rem" },
                fontFamily: "ABLE",
                lineHeight: "22px",
                pb: "0.5rem",
              }}
            >
            Click here to learn about a CapnoLearning {" "}
              <Typography
                component={Link}
                to="/course"
                // target="_blank"
                color={"rgb(109 79 160)"}
                fontWeight={600}
                sx={{
                  fontSize: { sm: "1.375rem", xs: "1.2rem" },
                  fontFamily: "ABLE",
                }}
              >
                 SHORT COURSE.
              </Typography>{" "}
              {/* in <strong>CapnoLearning</strong>. */}
            </Typography>
            <Typography
              sx={{
                fontSize: { sm: "1.375rem", xs: "1.2rem" },
                fontFamily: "ABLE",
                lineHeight: "22px",
                pb: "1rem",
              }}
            >
            Click here to learn about a CapnoLearning {" "}
              <Typography
                component={"a"}
                href="https://e-campus-registration.bp.edu/special/capnolearning?showbrochure=true"
                target="_blank"
                color={"rgb(109 79 160)"}
                fontWeight={600}
                sx={{
                  fontSize: { sm: "1.375rem", xs: "1.2rem" },
                  fontFamily: "ABLE",
                }}
              >
                 CERTIFICATION.
              </Typography>{" "}
              {/* in <strong>CapnoLearning</strong>. */}
            </Typography>

            <Typography
              sx={{
                fontSize: { sm: "1.375rem", xs: "1.2rem" },
                fontFamily: "ABLE",
                lineHeight: "22px",
                pb: "1rem",
                pt: "1rem"
              }}
            >
            Click here for informative {" "}
              <Typography
                component={Link}
                to="/interview"
                // target="_blank"
                color={"rgb(109 79 160)"}
                fontWeight={600}
                sx={{
                  fontSize: { sm: "1.375rem", xs: "1.2rem" },
                  fontFamily: "ABLE",
                }}
              >
                 INTERVIEWS
              </Typography>{" "}
              {/* in <strong>CapnoLearning</strong>. */}
            </Typography>
          </Grid>
          <Grid item md={4.6} sm={5} xs={12} position={"relative"}>
            <Box textAlign={"center"}>
              <Typography
                component={"img"}
                src={capnoKit_new}
                width={"85%"}
                // mt={"3rem"}
                // sx={{ position: { sm: "absolute" }, bottom: "0" }}
              />
            </Box>

            {/* <ThreeSixty
              amount={36}
              imagePath={"/untitled"}
              fileName="0_{index}.jpg"
              spinReverse 
              initialImageIndex={21} 
            /> */}
            {/* <Box
              sx={{
                display: {
                  md: "block",
                  sm: "none",
                  xs: "none",
                  "&:hover": {
                    "& .MuiTypography-root": {
                      // display: "none",
                      background: "#0000007d",
                      opacity: "0.8rem",
                      padding: "0.6rem",
                      borderRadius: "0.5rem",
                      // display: "none",
                      display: "none ! important",
                    },
                  },
                },
              }}
            >
              <Typography
                component={"p"}
                sx={{
                  color: "#fff",
                  position: "absolute",
                  top: "23%",
                  left: "20%",
                  zIndex: 10,
                  background: "#0000007d",
                  opacity: "0.8rem",
                  padding: "0.6rem",
                  borderRadius: "0.5rem",
                  "&:hover": {
                    // display: "none",
                    display: "none ! important",
                    // display: "block ! important",
                  },
                }}
              >
                Hold mouse to drag and rotate{" "}
                <SyncIcon sx={{ color: "white", fontSize: "1rem" }} />
              </Typography>
              <ReactImageTurntable
                images={images}
                initialImageIndex={24}
                autoRotate={{ disabled: rotationDisabled, interval: 100000000 }}
                // autoRotate={{ enabled: false, interval: 2000 }}
                onPointerDown={() => setRotationDisabled(true)}
                onPointerUp={() => setRotationDisabled(false)}
                onKeyDown={handleKeyDown}
                onKeyUp={() => setRotationDisabled(false)}
                style={{
                  cursor: "grab",
                  marginTop: "-30px",
                  // border: "2px solid red",
                  transform: "translate(0px, -25px) scale(1.25)",
                  zIndex: 1,
                }}
              />
            </Box> */}
            <Typography
              sx={{
                // display: { md: "block", sm: "none", xs: "none" },
                width: { sm: "75%", xs: "100%" },
                margin: "0 auto",
                // marginTop: "-10px",
                fontSize: { sm: "18px", xs: "18px" },
                fontFamily: "ABLE",
                lineHeight: { sm: "25px", xs: "25px" },
                textAlign: { sm: "start", xs: "justify" },
                color: "rgb(109 79 160)",
                fontWeight: "600",
    
              }}
            >
              {/* NOTE:{" "} */}
              {/* <Typography
                      component={"span"}
                      sx={{
                        fontSize: { sm: "18px", xs: "18px" },
                        fontFamily: "ABLE",
                        textAlign: { sm: "start", xs: "justify" },
                        color: "#040404",
                        fontWeight: "600",
                      }}
                    >
                      Image larger than instrument{" "}<br/>z */}
              <Typography
                component={"span"}
                sx={{ fontWeight: "600", fontFamily: "ABLE" }}
              >
                {/* (Actual size: 9.7 X 7.6 X 3.2 cm) */}
                 <Typography component={"p"} sx={{textDecoration:"underline",fontSize:"15px",cursor:"pointer",fontWeight: "600"}} onClick={()=>setView(true)}>Click here to view more images</Typography>
              </Typography>
              {/* </Typography> */}
            </Typography>

            <Box
              sx={{
                //  border:"1px solid",
                mt: { md: "1.5rem", xs: "1rem" },
                boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.15)",
                borderRadius: "6px",
                width: { md: "75%", xs: "100%" },
                pb: "0.5rem",
                margin: "0 auto",
              }}
            >
              <Box>
                <Box
                  sx={{
                    float: "right",
                    width: { md: "37%", xs: "31%" },
                    display: "flex",
                    justifyContent: "center",
                    // paddingLeft: { md: "rem" },
                    // paddingBottom: { md: "0", xs: "1.7rem" },
                  }}
                >
                  <Box
                    component={Link}
                    to={"/book"}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      component={"img"}
                      src={capno_trainer_img}
                      sx={{
                        float: "right",
                        mt: "0.8rem",
                        // mr:"1rem",
                        width: { md: "100px", sm: "95px", xs: "95px" },
                        height: { md: "135px", sm: "130px", xs: "130px" },
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ p: { sm: "1rem 1rem 0", xs: "1rem 1rem 0" } }}>
                  <Typography
                    className="title"
                    sx={{
                      fontSize: {
                        sm: "20px",
                        xs: "18px",
                        lineHeight: "10px",
                        fontFamily: "ABLE",
                        fontWeight: "500",
                      },
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        fontWeight: "600",
                        fontFamily: "ABLE",
                        fontSize: "18px",
                      }}
                    >
                      eBook:{" "}
                    </Typography>
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "ABLE",
                        fontSize: "18px",
                        color: "#000",
                      }}
                    >
                      CapnoLearning™{" "}
                    </Typography>
                    <br />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "ABLE",
                        fontSize: "18px",
                        color: "#000",
                      }}
                    >
                      An Introductory Guide ($35){" "}
                    </Typography>
                  </Typography>
                  <Typography
                    className="title"
                    sx={{
                      fontSize: {
                        sm: "20px",
                        xs: "18px",
                        lineHeight: "10px",
                        fontFamily: "ABLE",
                        fontWeight: "500",
                      },
                    }}
                  >
                    <br />
                    <Typography
                      component={"span"}
                      sx={{
                        fontWeight: "600",
                        fontFamily: "ABLE",
                        fontSize: "18px",
                        lineHeight: "10px",
                      }}
                    >
                      COURSE:{" "}
                    </Typography>
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "ABLE",
                        fontSize: "18px",
                        color: "#000",
                      }}
                    >
                      CapnoLearning™{" "}
                    </Typography>
                    <br />
                    <Typography
                      component={"span"}
                      sx={{
                        fontFamily: "ABLE",
                        fontSize: "18px",
                        color: "#000",
                      }}
                    >
                      Audio & Video Course ($199){" "}
                    </Typography>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: {
                      sm: "1rem 1rem 0",
                      xs: "1rem 1rem 0",
                      lineHeight: "10px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      py: "0.5rem",
                      fontSize: { sm: "18px", xs: "18px" },
                      fontFamily: "ABLE",
                      lineHeight: { sm: "23px", xs: "23px" },
                      textAlign: { sm: "start", xs: "start" },
                    }}
                  >
                    Make a tax-deductible purchase from
                    <br /> Breathing Science, Inc.
                    <br /> A 501(c)(3) nonprofit corporation
                  </Typography>
                  <Typography
                    sx={{
                      py: "0.5rem",
                      fontSize: { sm: "18px", xs: "18px" },
                      fontFamily: "ABLE",
                      lineHeight: { sm: "23px", xs: "23px" },
                      textAlign: { sm: "start", xs: "start" },
                    }}
                  >
                    Purchase the{" "}
                    <a
                      style={{
                        textDecoration: "underline",
                        color: "rgb(109 79 160)",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                      target="_blank"
                      href="https://www.thebsj.org/a/2147732394/GYhjaEjN"
                    >
                      eBook
                    </a>{" "}
                    Purchase the{" "}
                    <a
                      style={{
                        textDecoration: "underline",
                        color: "rgb(109 79 160)",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                      href="https://www.thebsj.org/a/2147786352/WYwL65Ls"
                      target="_blank"
                    >
                      COURSE.
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box pt="1.5rem">
          <Copyright />
        </Box>
      </Box>
      <ViewImages view={view} setView={setView}/>
      <VideoPop
        subscribeModal={videoModal}
        setSubscribeModal={setVideoModal}
        videoLink={"https://admin.betterphysiology.com/trecordings/645e8075b1979CapnoTrainer_Go_Software_Webinar.mp4"}
      />
    </Container>
  );
};

export default Home;
