import React, { useEffect } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Title from "../../Components/Title";
import NewTypography from "../../Components/NewTypography";
import Copyright from "../../Components/Copyright";
import EmailTypography from "../../Components/EmailTypography";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Components/Navbar";

const Distributors = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Container>
      <Navbar />
      <Box
        sx={{ color: "#000", mt: "2rem" }}
        data-aos="fade-up"
        data-aos-duration="500"
      >
       <Box sx={{
        display:"flex",justifyContent:"space-between",pb:"0.7rem",flexDirection:{sm:"row",xs:"column"},alignItems:"center",
        gap:"0.5rem"
       }}>
       <Typography
            variant="h2"
            sx={{
              fontFamily: "ABLE",
              fontSize: "2.3rem" ,
              color: "rgb(109 79 160)",            
              lineHeight: "2.6rem",
              fontWeight:600,
              textAlign: { md: "inherit", sm: "inherit", xs: "center" },
            }}
          >
          Distributors
          </Typography>
        <Button
        component="a"
        href="https://distributor.capnolearning.com/"
        target="_blank"
              sx={{
                background: "rgb(109 79 160) !important",
                color: "#fff !important",
                fontFamily: "ABLE",
                p: "0.5rem 1rem",
                fontSize: "18px !important",
                lineHeight: "initial !important",
                border: "2px solid #C5A7CF !important",
                width: {sm:"auto",xs:"200px"},
                "&:hover": {
                  background: "rgb(109 79 160)",
                },
              }}
            >
            Distributor Login
            </Button>
       </Box>
        <Grid container spacing={3}>
          <Grid item md={6} sm={12} xs={12}>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                USA
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"CORPORATE OFFICES"}
                  fsm={"22px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"28px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"BETTER PHYSIOLOGY LTD, Peter M. Litchfield, Ph.D."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"109 E. 17th Street, Cheyenne, WY 82001 USA"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 1.307.773.0888 . Fax: 1.307.773.0887 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"instruments@betterphysiology.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
                   <NewTypography
                  text={"Santa Fe Office"}
                  fsm={"20px"}
                  fxs={"20px"}
                  marginTop={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                  <NewTypography
                  text={"7 Camino de Rey Cir"}
                  fsm={"20px"}
                  fxs={"20px"} 
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                  <NewTypography
                  text={"Santa Fe, NM  87506  USA"}
                  fsm={"20px"}
                  fxs={"20px"} 
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                  <NewTypography
                  text={"Tel: 1.505.820.3376"}
                  fsm={"20px"}
                  fxs={"20px"} 
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                  <EmailTypography
                  text={"Email:"}
                  email={"pl@betterphysiology.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
              <Box mt={"1rem"}>
                <NewTypography
                  text={"FLORIDA"}
                  fsm={"22px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"28px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"NEUROACROBATICS, LLC, Megan Baerg"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"3504 W Paxton Ave, Tampa, Florida 33611"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
               
                <EmailTypography
                  text={"Email:"}
                  email={"megan@neuroacrobatics.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
                 <NewTypography
                  text={"Tel: 929-949-9110"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
              </Box>
              <Box mt={"1rem"}>
                <NewTypography
                  text={"NEW MEXICO"}
                  fsm={"22px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"28px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"BREATHING SCIENCE INSTITUTE, Denise Campbell"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"2404 S. Locus Street, Suite #5, Las Cruces, NM 88001"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 575-405-7210 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"denise@swsportandspine.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
              <Box mt={"1rem"}>
                <NewTypography
                  text={"NEW YORK"}
                  fsm={"22px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"28px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"BIOFEEDBACK RESOURCES INTERNATIONAL, Harry Campbell"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "109 Croton Ave, 2nd Floor, Suite #5, Ossining, NY 10562"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 1.914-762-4646 . Fax:914-762-2281 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />

                <EmailTypography
                  text={"Email:"}
                  email={"sales@biofeedbackinternational.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />

                <NewTypography
                  text={"Respiratory Fitness, LLC., Alexander Stewart"}
                  marginTop={"20px"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  
                  color={"#040404"}
                />
                <NewTypography
                  text={"244 5th Ave, Ste 2508, New York, NY 10001"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 646.652.6302, 212.462.9397 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"respiratoryfitness@gmail.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
                <NewTypography
                  text={"KNOSIS Physiotherapy & Wellness , Tracey Vincel"}
                  marginTop={"20px"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  
                  color={"#040404"}
                />
                <NewTypography
                  text={"7 West 22nd 8th Floor"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"New York,  NY 10010"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
              
                <EmailTypography
                  text={"Email:"}
                  email={"tvincel@knosiswellness.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
                  <NewTypography
                  text={"Tel: 212-906-4440 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                 
              
              </Box>
              <Box mt={"1rem"}>
                <NewTypography
                  text={"TEXAS"}
                  fsm={"22px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"28px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"APERION CENTER, Danica Warren"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"919 Congress Ave. Ste 525, Austin, TX 78701"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"danica@apeironcenter.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
                   <NewTypography
                  text={"Tel: 512-522-0966"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
               <NewTypography
                  text={"Website: "}
                  link={"https://www.apeironcenter.com/"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem", 
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                ARAB COUNTRIES
              </Typography>

              <Box mt={"0.2rem"}>
                <NewTypography
                  text={"INTER MARKETS LIMITED, Omar Chtioui"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "BLK A, 15/F Hillier Commercial,"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                  <NewTypography
                  text={
                    "Bldg 65-67 Bonham Strand East, Sheung Wan, H.K."
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"UAE, +1.852.66512620"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                
                <EmailTypography
                  text={"Email:"}
                  // email={"info@breath-coach.com"}
                  email={"info@intermarketslimited.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                ASIAN COUNTRIES
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"DOCTOR AND CO CORPORATION LTD., Dr. Amornpong Vachiramon"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "128/366 L.1 Phayathai Plaza Building 33th Floor, Phayathai Road,"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                 <NewTypography
                  text={
                    "Tung Phayathai, Ratchathewi, Bangkok Thailand 10400"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 021099906"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"contact@drandco.co.th"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                AUSTRALIA
              </Typography>

              <Box mt={"0.5rem"}>
                <NewTypography
                  text={"BREATHE ME, Angie & Shane Saunders"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"1 Parrot Court, Gilston QLD 4221"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                  <EmailTypography
                  text={"Email:"}
                  email={"info@breatheme.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
                <NewTypography
                  text={"Tel: +61429777729"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
              
              </Box>
              <Box mt={"20px"}>
                <NewTypography
                  text={"BREATHING WELL, LLC, Roger Price"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"6a/3 Campbell Pde, Bondi Beach NSW 2026"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 0423-589-588 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"rogerlprice@gmail.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                AUSTRIA
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"ATEMKOMPETENZ, Wolfgang J. Fellner, Ph.D."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Villengasse 15, 2214 Auersthal, Austria"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 0043.677.64050501."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"instrumente@atemkomptenz.at"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                CANADA
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={
                    "PROACTIVE EDUCATION, Laurie McLaughlin, DScPT"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "94 North Shore Blvd W, Burlington. Ontario L7T 4G3, Canada"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 1.905.338.2898 "}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"laurie@proactivehealth.ca"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />

                <NewTypography
                  text={
                    "CONSPIRE HEALTH, Lisa V. Kusch"
                  }
                  fsm={"20px"}
                  marginTop={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"#35-3814 Burron Ave"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Saskatoon, SK S7P0E4, CANADA"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
               
                <EmailTypography
                  text={"Email:"}
                  email={"lv@amplifyrehab.ca"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
                 <NewTypography
                  text={"Tel: +1.306.612.3777"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
              </Box>
            </Box>
            
          
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
          <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                FRANCE
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"OLIVIER MORTARA"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"3 rue Emile Zola, 40510 Seignosse, France"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: +33 630727534"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                 <NewTypography
                  text={"Website: "}
                  link={"https://oliviermortara.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                
                <EmailTypography
                  text={"Email:"}
                  email={"om@oliviermortara.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
          <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem", 
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                GERMANY
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"AtmungAktiv, Michael Schmidt"}
                  // text={"HANDPAN ONLINE, Michael Schmidt"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "Erlengasse 5, 63322 Roedermark (Frankfurt am Main)"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                
                 <NewTypography
                 text={"Website: "}
                  link={"https://www.atmungaktiv.de"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel Hotline: +49 (0) 800 000 9448"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                 <NewTypography
                  text={"Tel Mobile: +49 (0) 177 6272054"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                 <NewTypography
                  text={"Tel Showroom: +49 (0) 6074 698 68 70"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"info@atmungaktiv.de"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pt: "2rem",
                  pb: "0.1rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                INDIA
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"HIMOTECH GLOBAL PVT LTD, Yasir Khan"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "B-01, Sector-2, D-84, Noida, Gautam Budh Nagar, UP 201301"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: +91-8053143035"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"info@himotechglobal.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
           
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                ISRAEL
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"SKILLS DEVELOPMENT CENTER, Dan Tune"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"9 Halilach Street, Herzlia 46601, Israel."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel and Fax: 972.9.9583372 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"tuneedna@bezeqint.net"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                JAPAN
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"CAPNOTRAINER JAPAN, Dr. Kohei Miyoshi"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "2179-4-104Kamimizo, Chuoku, Sagamihara, Kanagawa, Japan"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 042.733.8186 . Fax: 042.733.8186 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"miyoshisun@yahoo.co.jp"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
                   <NewTypography
                  text={"Website: "}
                  link={"https://www.capnotrainer.jp"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                NEW ZEALAND
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"BUTEYKO BREATHING CLINICS LTD, Glenn White"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "20 Arthur Street, Freemans Bay, Auckland 1011, New Zealand"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 64.9.360.6291 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"glenn@buteykobreathing.nz"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                SCANDINAVIA
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"RESPIRATORY FITNESS INSTITUTE AS, Alexander Stewart"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Gustav Vigelands Vei 15B, 0274 Oslo, Norway"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 47.92647747 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"respiratoryfitness@gmail.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
            {/* <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                SOUTH AFRICA
              </Typography>
              <Box mt={"1rem"}>
                <NewTypography
                  text={"DR D.E.L. EICHHOFF, INC, Dr. Dittmar Eichhoff"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"6 Allen Street, Grahamstown, Eastern Cape Province"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Tel: 27.46.6223449 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"reception@eichhoff.co.za"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
              </Box> */}
              <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem", 
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                SPAIN
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"AtmungAktiv España, Michael Schmidt"}
                  // text={"HANDPAN ONLINE, Michael Schmidt"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "Av. Son Rigo 23, 07610  Palma de Mallorca"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                
                 <NewTypography
                 text={"Website: "}
                  link={"https://www.atmungaktiv.de"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
               
                 <NewTypography
                  text={"Tel Mobile: +49 (0) 177 6272054"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                 <NewTypography
                  text={"Tel Showroom: +49 (0) 6074 698 68 70"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"info@atmungaktiv.de"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
              <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                SWITZERLAND
              </Typography>
              <Box mt={"1rem"}>
                <NewTypography
                  text={"ARA.HEALTH SARL, Chris Christiansson"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                 <NewTypography
                  text={"Rue de Village 40, 1659 Rougemont"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                {/* <NewTypography
                  text={"Rue de Village 40"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                /> */}
                {/* <NewTypography
                  text={"1659 Rougemont"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                /> */}
                <EmailTypography
                  text={"Email:"}
                  email={"info@ara.health"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
           
            </Box>
            
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                THE NETHERLANDS
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"PHYSIOMOTION, Stephanie Leyh and Joost van Mierlo."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={
                    "Sint Jansshof 8, 3011 SG Rotterdam, The Netherlands"
                  }
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"info@physiomotion.nl"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                TURKEY
              </Typography>

              <Box mt={"1rem"}>
                <NewTypography
                  text={"NEVSAH ENSTITU LTD, Nevsah Karamehmet"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"Withco Çınarlı, 1572/1. Sk. No:33,"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <NewTypography
                  text={"35170 Konak/İzmir, Türkiye"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"fidannevsah@gmail.com"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
                <NewTypography
                  text={"Tel: 90.530.469.1111 ."}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "28px",
                  fontFamily: "ABLE",
                  lineHeight: { sm: "2.5rem", xs: "2rem" },
                  textAlign: { sm: "start", xs: "center" },
                  pb: "0.1rem",
                  pt: "2rem",
                  borderBottom: "1px solid rgb(109 79 160)",
                }}
              >
                UNITED KINGDOM
              </Typography>

              <Box mt={"1rem"}>
              <NewTypography
                  text={"CAPNOTRAINER UK, VJ Odedra"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />                             
              <NewTypography
                  text={"27 Russell Road, London N20 0TN, England"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />               
                {/* <NewTypography
                  text={"England"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                /> */}
                <NewTypography
                  text={"Tel: +44 (0) 7848 300000"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                  color={"#040404"}
                />
                <EmailTypography
                  text={"Email:"}
                  email={"VJ@CapnoTrainer.co.uk"}
                  fsm={"20px"}
                  fxs={"20px"}
                  lsm={"30px"}
                  lxs={"26px"}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box pt={"4rem"}>
          <Copyright />
        </Box>
      </Box>
    </Container>
  );
};

export default Distributors;
